import requset from "utils/request";
import baseURL from "./env";
/* 首页书架 */
export function getBookList(params) {
  return requset({
    url: `${baseURL.apiURL}/book/core/pc/open/book/list`,
    method: "GET",
    params
  });
}
// 书籍详情
//=====cdn
export function BookDetail(params) {
  return requset({
    url: `${baseURL.apiURL}/book/cdn/client/book`,
    method: "GET",
    params
  });
}
// 书籍目录
export function Bookmenu(params) {
  return requset({
    url: `${baseURL.apiURL}/book/cdn/menu`,
    method: "GET",
    params
  });
}
// 书籍内容
// ==========免费章节接口cdn
export function chapterCon(params) {
  return requset({
    url: `${baseURL.apiURL}/book/cdn/client/chapter`,
    method: "GET",
    params
  });
}
/* 收费 */
export function chapternoCon(params) {
  return requset({
    url: `${baseURL.apiURL}/book/cdn/client/chapter`,
    method: "GET",
    params
  });
}
export function buyChapter(params) {
  return requset({
    url: `${baseURL.apiURL}/book/core/pc/chapter/buy`,
    method: "POST",
    params
  });
}

/* shelf */
export function homeshelf(params) {
  return requset({
    url: `${baseURL.apiURL}/book/cdn/home`,
    method: "GET",
    params
  });
}
export function moreshelf(params) {
  return requset({
    url: `${baseURL.apiURL}/book/cdn/shelf`,
    method: "GET",
    params
  });
}
/* fb最新记录 */
export function booktitle(params) {
  return requset({
    url: `${baseURL.apiURL}/book/cdn/get/book/title`,
    method: "GET",
    params
  });
}
