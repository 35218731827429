<template>
  <div class="topmargin">
    <topNav :title="$route.query.name" :border="true"></topNav>
    <div class="ShelfRecommend normalBox threeShelf" v-if="shelf.length > 0">
      <ul
        class="clearfix shelf1"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="loading"
        infinite-scroll-distance="10"
      >
        <li v-for="book in shelf" class="threeItem clearfix" :key="book.id">
          <router-link :to="'/book/' + book.id" class="fl">
            <img v-lazy="book.iconUrl" alt="" />
          </router-link>
          <div class="fl rbox">
            <router-link :to="'/book/' + book.id" class="bookname">
              {{ book.name }}
            </router-link>
            <div class="bookintro">
              <p class="introCon">{{ book.introduce }}</p>
              <div class="clearfix">
                <router-link :to="'/book/' + book.id" class="fl authorName">{{
                  book.author.name
                }}</router-link>
                <div class="fr clearfix">
                  <router-link :to="'/book/' + book.id" class="tag fl">{{
                    book.sort
                  }}</router-link>
                  <router-link :to="'/book/' + book.id" class="fl tag finish">{{
                    book.finished ? "完結" : "連載中"
                  }}</router-link>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <nodata v-if="shelf.length == 0" class="posCen">
      <div slot="datatitle" class="nodata-tip">暫無數據！</div>
    </nodata>
    <div v-if="showMore && shelf.length > 0" class="nonehint">
      <img src="~img/account/nonehint.png" alt="" />
    </div>
  </div>
</template>

<script>
import { moreshelf } from "apiurl/book";
export default {
  data() {
    return {
      shelf: [],
      pageNum: 0,
      loading: false,
      hasNextPage: true,
      showMore: false
    };
  },
  mounted() {
    this.loadMore()
  },
  methods: {
    async loadMore() {
      if (this.hasNextPage) {
        this.loading = false;
        this.pageNum++;
        let res = await moreshelf({
          pageNum: this.pageNum,
          pageSize: 10,
          shelfId: this.$route.query.id
        });
        if (res.success) {
          res.data.items.forEach(item => {
            item.introduce = item.introduce.substring(0, 30) + "...";
          });
          this.hasNextPage = res.data.hasNextPage;
          this.shelf = [...this.shelf, ...res.data.items];
          if (!this.hasNextPage) {
            this.showMore = true;
            this.loading = true;
          } else {
            this.showMore = false;
            this.loading = false;
          }
        }
      } else {
        this.loading = true;
        this.showMore = true;
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.topmargin
    width 100vw
    overflow hidden
    margin-top 100px
.introCon
    padding-bottom 16px
    border-bottom 2px solid #E9ECF0
    line-height 33px
    margin-bottom 15px
.authorName
    font-size 22px
    color rgba(172, 175, 194, 1)
    line-height 30px
.nonehint
    margin 65px auto 30px
    width 209px
    img
        width 209px
</style>

